import { modelListingUrl } from "@jammable/ui-core";
import { Badge } from "@jammable/ui-components/Badge";
import Link from "next/link";

interface Category {
  name: string;
  icon: string;
  param: string;
}

const badges: Category[] = [
  {
    name: `Cartoon`,
    icon: "🦖 ",
    param: "cartoon",
  },
  {
    name: `Music`,
    icon: "🎹",
    param: "music",
  },
  {
    name: `Anime`,
    icon: "⛩️",
    param: "anime",
  },
  {
    name: `Gaming`,
    icon: "🎮",
    param: "gaming",
  },
  {
    name: `German`,
    icon: "🇩🇪",
    param: "german",
  },
  {
    name: `Korean`,
    icon: "🇰🇷",
    param: "korean",
  },
  {
    name: `French`,
    icon: "🇫🇷",
    param: "french",
  },
  {
    name: `Italian`,
    icon: "🇮🇹",
    param: "italian",
  },

  {
    name: `Public figure`,
    icon: "📢",
    param: "figure",
  },
];

export function CategoriesBar() {
  return (
    <div className="tw-overflow-x-auto tw-overflow-y-hidden">
      <div className="tw-flex tw-gap-2 tw-py-5 sm:tw-flex-wrap sm:tw-justify-center">
        {badges.map(({ icon, name, param }) => (
          <div key={param} className="tw-shrink-0">
            <Link href={modelListingUrl(param)}>
              <Badge className="tw-pb-2 tw-pt-2.5">
                <span className="tw-hidden sm:tw-inline-block">{icon}</span>
                <span>{name}</span>
              </Badge>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
