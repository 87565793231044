import React from "react";
import { cn } from "@jammable/ui-core";
import { Image } from "../Media";

export const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    noborder?: boolean;
    horizontal?: boolean;
  }
>(({ noborder, horizontal, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "tw-group tw-relative tw-min-w-40 tw-max-w-96 tw-overflow-hidden tw-rounded-3xl sm:tw-h-72 md:tw-h-80",
      "tw-bg-[linear-gradient(to_bottom,_#f4eafa,_#fff_20%)]", // @TODO custom bg move to theme
      { "tw-border tw-border-solid tw-border-black/10": !noborder },
      {
        "sm:tw-flex tw-pb-0 sm:tw-justify-between sm:tw-flex-row-reverse tw-max-w-none":
          horizontal,
      },
      className,
    )}
    {...props}
  />
));

Card.displayName = "Card";

export const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    nopadding?: boolean;
    highlight?: boolean;
  }
>(({ nopadding, highlight, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "tw-py-4",
      { "tw-px-5": !nopadding },
      { "sm:tw-w-2/5": highlight },
      className,
    )}
    {...props}
  />
));

CardContent.displayName = "CardContent";

export type CardImageProps = {
  rounded?: boolean;
  highlight?: boolean;
  blurDataURL?: string;
};

export const CardImage = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    CardImageProps & { src: string; alt: string }
>(({ className, rounded, highlight, src, alt, blurDataURL, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "tw-h-44 tw-overflow-hidden",
      { "tw-rounded-3xl": rounded },
      { "sm:tw-h-auto sm:tw-w-3/5": highlight },
      { "sm:tw-h-52 md:tw-h-60": !highlight },
      className,
    )}
    {...props}
  >
    <Image
      width={320}
      alt={alt}
      src={src}
      fill={true}
      className={cn("tw-h-auto", { "-tw-translate-y-4": highlight })}
      placeholder={blurDataURL ? "blur" : "empty"}
      blurDataURL={blurDataURL}
    />
  </div>
));

CardImage.displayName = "CardImage";

export const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ children, className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "tw-m-0 tw-mb-1 tw-truncate tw-text-lg tw-font-bold tw-tracking-tight  md:tw-text-xl",
      className,
    )}
    {...props}
  >
    {children}
  </h3>
));

CardTitle.displayName = "CardTitle";

export const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "tw-m-0 tw-text-sm tw-font-bold tw-leading-tight tw-opacity-50",
      className,
    )}
    {...props}
  />
));

CardDescription.displayName = "CardDescription";

export const CardAuthor = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      "tw-text-sm tw-font-bold tw-leading-tight tw-opacity-50",
      className,
    )}
    {...props}
  />
));

CardAuthor.displayName = "CardAuthor";
