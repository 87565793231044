import { getAuthBearerHeaders } from "../shared";

export interface FetchAudioJobResponse {
  status: "created" | "processing" | "complete" | "error";
  downloadUrl: string;
}

export async function fetchAudioJobStatus(id: string): Promise<FetchAudioJobResponse> {
  const response = await fetch(`/api/audio-tool/audio-job/${id}`, {
    headers: {
      ...(await getAuthBearerHeaders()),
    },
  });

  if (response.status != 200) {
    throw new Error(`${response?.statusText}`);
  }

  const data = await response.json();
  return { status: data.status, downloadUrl: data.download.url };
}
