import { NextSeo } from "next-seo";
import { DefaultLayout } from "@jammable/ui-core";
import {
  HomePage as HomePageComponent,
  HomePageProps,
} from "@jammable/ui-homepage";
import { useAnalytics } from "../context/AnalyticsContext";
import { GetServerSidePropsContext } from "next";
import connectToDb from "../utils/mongodb";
import Model from "../models/Model";

export default function HomePage({
  trending,
  categoryTopModels,
}: Omit<HomePageProps, "onLine">) {
  const mixpanel = useAnalytics();
  return (
    <>
      <NextSeo
        title={"Jammable | Create AI Covers with your Favorite Voices!"}
        description={
          "Create AI covers using AI in seconds with Jammable, with hundreds of community uploaded AI voice models available for creative use now!"
        }
        themeColor="#782DC8"
        openGraph={{
          title: `Jammable | Create AI Covers with Your Favorite Voices | AI-Powered Music Creation`,
          description:
            "Create AI covers using AI in seconds with Jammable, with hundreds of community uploaded AI voice models available for creative use now!",
          type: "website",
          locale: "en_IE",
          siteName: "Jammable",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <DefaultLayout>
        <HomePageComponent
          trending={trending}
          categoryTopModels={categoryTopModels}
          analytics={{
            track(eventName: string, properties?: Record<string, unknown>) {
              mixpanel.track(eventName, { page: "Homepage", ...properties });
            },
          }}
        />
      </DefaultLayout>
    </>
  );
}

export async function getServerSideProps({ res }: GetServerSidePropsContext) {
  res.setHeader(
    "Cache-Control",
    "public, s-maxage=1800, stale-while-revalidate=1800",
  );

  const { trending, categoryTopModels } = await getHomepageModels();

  return {
    props: { trending, categoryTopModels },
  };
}

async function fetchCategoriesAndTopModels() {
  await connectToDb();

  const commonStages = [
    {
      $project: {
        image: 1,
        artistname: 1,
        uses: 1,
        urlParam: 1,
        isPublic: 1,
        isLive: 1,
        isCommunity: 1,
        likes: 1,
        badges: 1,
        createdAt: 1,
        info: 1,
        accessIds: 1,
        user: 1,
        // Include any additional fields that you need
      },
    },
  ];

  try {
    // Define an array of categories you want to filter by
    const categories = ["cartoon", "anime", "gaming", "korean", "german"];

    // Dynamically construct the $facet stage
    const facetStage: Record<string, unknown> = {};
    categories.forEach((category) => {
      facetStage[category] = [
        {
          $match: {
            $or: [
              { classification: { $in: [category] } },
              { badges: { $in: [category] } },
            ],
          },
        },
        { $sort: { uses: -1 } },
        { $limit: 8 },
        ...commonStages,
      ];
    });

    const models = await Model.aggregate([
      {
        $match: {
          isPublic: true,
          deleted: false,
          private: false,
          isLive: true,
          isRemoved: false,
        },
      },
      // @ts-expect-error seems like outdated types
      { $facet: facetStage },
    ]);

    const serializable = JSON.parse(JSON.stringify(models));

    // Construct response dynamically based on categories
    const responseModels: Record<string, unknown> = {};
    categories.forEach((category) => {
      responseModels[category] = serializable[0][category];
    });

    return responseModels;
  } catch (error) {
    console.error("Error fetching models: ", error);
    return {};
  }
}

async function fetchTrendingModels() {
  await connectToDb();

  try {
    const models = await Model.aggregate([
      {
        $match: {
          "trending.isTrending": true,
          deleted: false,
          private: false,
          isLive: true,
          isPublic: true,
          isRemoved: false,
        },
      },

      {
        $project: {
          image: 1,
          artistname: 1,
          uses: 1,
          urlParam: 1,
          isPublic: 1,
          isLive: 1,
          isCommunity: 1,
          likes: 1,
          badges: 1,
          info: 1,
          accessIds: 1,
          user: 1,
        },
      },
      {
        $sort: { "trending.position": 1 },
      },
      {
        $limit: 6,
      },
    ]);

    return JSON.parse(JSON.stringify(models));
  } catch (error) {
    console.error("Error fetching models: ", error);
    return [];
  }
}

async function getHomepageModels() {
  const [trending, categoryTopModels] = await Promise.all([
    fetchTrendingModels(),
    fetchCategoriesAndTopModels(),
  ]);

  return {
    trending,
    categoryTopModels,
  };
}
