import { FaArrowRight } from "react-icons/fa";
import { Image } from "@jammable/ui-components/Media";
import {
  WithTracking,
  cn,
  customModelUrl,
  secondaryButtonStyles,
} from "@jammable/ui-core";
import footerMicImage from "@jammable/ui-components/Media/backgrounds/cta-mic.png";
import Link from "next/link";

export function CreateYourOwnCTA({ track }: WithTracking) {
  return (
    <Link
      href={customModelUrl()}
      onClick={() => track("Clicked on Create Your Own Voice CTA")}
    >
      <div className="tw-relative tw-rounded-4xl tw-bg-gradient-to-r tw-from-violet-950 tw-to-violet-800 tw-p-8 sm:tw-p-4">
        <div className="-tw-left-12 tw-hidden sm:tw-absolute sm:-tw-bottom-[5px] sm:tw-block">
          <Image src={footerMicImage} width={300} height={310} alt="" />
        </div>
        <div className="sm:tw-pb-4 sm:tw-pl-48 sm:tw-pt-6">
          <h2 className="tw-m-0 tw-mb-4 tw-text-xl tw-font-black tw-text-white sm:tw-mb-6 md:tw-text-4xl">
            Create your own custom voice!
          </h2>
          <ol className="tw-m-0 tw-list-inside tw-list-decimal tw-p-0 tw-font-semibold tw-leading-6 tw-text-white/60">
            <li className="tw-m-0 tw-p-0">Upload your voice recordings.</li>
            <li className="tw-m-0 tw-p-0">Begin voice training.</li>
            <li className="tw-m-0 tw-p-0">
              Share it with the community or keep private!
            </li>
          </ol>
          <div
            className={cn(
              secondaryButtonStyles,
              "tw-relative tw-mt-4 tw-inline-block tw-w-full tw-rounded-5xl tw-text-center md:tw-float-right md:-tw-mt-8 md:tw-w-auto",
            )}
          >
            <span className="tw-flex tw-items-center tw-gap-1">
              Create New Voice <FaArrowRight className="tw-inline" size={18} />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}
