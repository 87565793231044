import { ReactNode } from "react";
import { DropdownMenuItem } from "@jammable/ui-components/DropdownMenu";
import { AiFillTwitterCircle, AiFillRedditCircle } from "react-icons/ai";
import { FaClipboardList, FaFacebook, FaShareSquare } from "react-icons/fa";
import { Button, cn, toast } from "@jammable/ui-core";
import { secondaryBadgeStyles } from "../Badge";
import Dropdown from "../../../../apps/web/components/dropdown/Dropdown";

const ShareMenuItem = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <DropdownMenuItem
    onClick={onClick}
    className={cn(
      secondaryBadgeStyles,
      " tw-w-full tw-cursor-pointer tw-justify-start tw-p-3 tw-py-2 tw-transition tw-duration-100 tw-ease-in-out hover:tw-bg-neutral-200 focus:tw-bg-neutral-200",
    )}
  >
    {children}
  </DropdownMenuItem>
);

export function ShareButton({ title }: { title: string }) {
  const handleShareOnTwitter = () => {
    open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(location.href)}`,
      "_blank",
      "width=550,height=420",
    );
  };

  const handleShareOnReddit = () => {
    open(
      `https://www.reddit.com/submit?title=${encodeURIComponent(title)}&url=${encodeURIComponent(location.href)}`,
      "_blank",
      "width=900,height=700",
    );
  };

  const handleShareOnFacebook = () => {
    open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(location.href)}`,
      "_blank",
      "width=600,height=400",
    );
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(location.href);
    // @TODO prob don't want to depend on toast directly
    toast.success("URL copied to clipboard!");
  };

  return (
    <Dropdown
      className="tw-flex tw-flex-col tw-gap-2"
      dropdownItems={
        <>
          <ShareMenuItem onClick={handleShareOnReddit}>
            <AiFillRedditCircle size={32} className="tw-text-red-500" /> Share
            to Reddit
          </ShareMenuItem>
          <ShareMenuItem onClick={handleShareOnFacebook}>
            <FaFacebook size={32} className="tw-text-blue-700" /> Share to
            Facebook
          </ShareMenuItem>
          <ShareMenuItem onClick={handleShareOnTwitter}>
            <AiFillTwitterCircle size={32} className="tw-text-blue-400" /> Share
            to Twitter
          </ShareMenuItem>
          <ShareMenuItem onClick={handleCopyUrl}>
            <FaClipboardList size={24} className="tw-text-purple-700" /> Copy
            URL
          </ShareMenuItem>
        </>
      }
    >
      <Button className="tw-w-full tw-rounded-4xl">
        <FaShareSquare size={20} className="tw-inline" /> Share
      </Button>
    </Dropdown>
  );
}
