import type { ModelInfo } from "@jammable/clientapi";
import {
  WithAnalytics,
  cn,
  effectsPageUrl,
  modelListingUrl,
} from "@jammable/ui-core";
import { CategoriesBar } from "@jammable/ui-components/CategoriesBar";
import { BackgroundImage, Icon } from "@jammable/ui-components/Media";
import { ModelGrid } from "@jammable/ui-components/ModelGrid";
import { ModelSearch } from "@jammable/ui-components/ModelSearch";
import { SocialBar } from "@jammable/ui-components/Social";
import noteIcon from "@jammable/ui-components/Media/animated/note.gif";
import heartIcon from "@jammable/ui-components/Media/animated/heart.gif";
import bgReverb from "@jammable/ui-components/Media/backgrounds/bgReverb.png";
import bgSpeedup from "@jammable/ui-components/Media/backgrounds/bgSpeedup.png";
import { Badge } from "@jammable/ui-components/Badge";
import { Banner } from "./components/Banner";
import { CreateYourOwnCTA } from "./components/CreateYourOwnCTA";
import Link from "next/link";
import { Input } from "@jammable/ui-components/Forms";
import { FaSearch } from "react-icons/fa";

export interface HomePageProps {
  trending: ModelInfo[];
  categoryTopModels: { [key: string]: ModelInfo[] };
}

function Section({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode | string;
}) {
  return (
    <section className={cn("tw-py-3 sm:tw-px-3 md:tw-py-8", className)}>
      {children}
    </section>
  );
}

function SectionHeading({
  children,
  className,
}: {
  children: React.ReactNode | string;
  className?: string;
}) {
  return (
    <h2
      className={cn(
        "tw-mb-4 tw-mt-0 tw-flex tw-items-center tw-gap-2 tw-text-3xl tw-font-black tw-text-black sm:tw-mb-6 md:tw-text-5xl",
        className,
      )}
    >
      {children}
    </h2>
  );
}

function SectionSubHeading({
  children,
  className,
}: {
  children: React.ReactNode | string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "tw-text-sm tw-font-semibold tw-tracking-normal",
        className,
      )}
    >
      {children}
    </div>
  );
}

const modelSearchPageUrl = (term: string): string => `/models?q=${term}`;

export function HomePage({
  trending,
  categoryTopModels,
  analytics = { track: () => {} },
}: HomePageProps & WithAnalytics) {
  return (
    <>
      <Banner />
      <SocialBar className="tw-relative tw-mx-auto -tw-mt-14 tw-hidden tw-overflow-hidden md:tw-block" />
      {/* <ModelSearch className="tw-m-auto tw-block tw-w-full tw-max-w-lg" /> */}
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center md:tw-px-28">
        <Input
          placeholder="Search all voices..."
          icon={<FaSearch size={20} />}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const searchTerm = (e.target as HTMLInputElement).value;
              window.location.href = modelSearchPageUrl(searchTerm);
            }
          }}
          className=" tw-w-full tw-justify-center tw-h-14 tw-rounded-full"
          inputClassName=" tw-rounded-l-full tw-pl-4 tw-text-gray-700"
        />
      </div>
      <CategoriesBar />
      <Section>
        <SectionHeading className="md:tw-hidden">
          {/* TODO i18n for they key */}
          <Icon src={heartIcon} height={"75px"} width={"72px"} alt="" />
          <div>
            Trending{" "}
            <SectionSubHeading>The Top Trending Voices Today</SectionSubHeading>
          </div>
        </SectionHeading>
        <ModelGrid<ModelInfo> items={trending} topPicks />
      </Section>

      <>
        {Object.keys(categoryTopModels).map((key, index) => (
          <>
            <Section key={key}>
              <SectionHeading>
                {/* TODO i18n for they key */}
                <Icon src={noteIcon} height={"69px"} width={"72px"} alt="" />
                <div>
                  <span className="tw-capitalize">{key}</span>{" "}
                  <SectionSubHeading>
                    The Top{" "}
                    <Link
                      className="tw-font-semibold tw-text-purple-700"
                      href={modelListingUrl(key)}
                    >
                      #{key}
                    </Link>{" "}
                    voices
                  </SectionSubHeading>
                </div>
              </SectionHeading>
              <ModelGrid<ModelInfo> items={categoryTopModels[key]} />
            </Section>
            {index === 0 && (
              <Section className="md:tw-pt-16">
                <CreateYourOwnCTA track={analytics.track} />
              </Section>
            )}
          </>
        ))}
      </>
    </>
  );
}
