import { Button, cn, modelPageUrl } from "@jammable/ui-core";
import millify from "millify";
import {
  Card,
  CardAuthor,
  CardContent,
  CardDescription,
  CardImage,
  CardTitle,
} from "../Card";
import { Icon } from "../Media";
import { Badge } from "../Badge";
import { rgbDataURL } from "../../../../apps/web/utils/tools";
import Link from "next/link";

export interface ModelForGrid {
  _id: string;
  urlParam: string;
  artistname: string;
  likes: number;
  uses: number;
  user: {
    username: string;
  };
  image: {
    url: string;
    blurhash: string;
  };
}

interface ModelGridItemProps {
  model: ModelForGrid;
  topPicks?: boolean;
  first?: boolean;
  idx?: number;
}

export function ModelGridItem({
  topPicks,
  first,
  model,
  idx,
}: ModelGridItemProps) {
  const { urlParam, artistname, likes, uses, image, user } = model;
  const isTopTrending = topPicks && first;

  const rgb = model?.image?.blurhash.split(",");

  return (
    <Link href={modelPageUrl(urlParam)}>
      <Card
        style={{
          animationDelay: idx ? `${0.05 * idx}s` : "0s",
        }}
        className={`${idx ? "card-fade-in tw-opacity-0" : "tw-opacity-1"}`}
        noborder={!topPicks}
        horizontal={first}
      >
        {isTopTrending && (
          <Badge
            style={{
              boxShadow: "0px 4px 32px 0px rgb(120, 45, 200, 0.2)",
            }}
            className="tw-absolute tw-right-2 tw-top-2 tw-z-50 tw-bg-purple-700/80 tw-py-1.5 tw-pl-2 tw-pr-3 tw-text-white hover:tw-bg-purple-700/80"
          >
            🔥 Trending
          </Badge>
        )}
        <CardImage
          rounded={!topPicks}
          highlight={first}
          src={image.url}
          alt={artistname}
          blurDataURL={rgbDataURL(rgb[0], rgb[1], rgb[2])}
        />
        <CardContent nopadding={!topPicks} highlight={first}>
          {topPicks && first && (
            <div className="tw-mb-1 tw-mt-2 tw-hidden tw-items-center tw-gap-2 tw-text-black sm:tw-block ">
              <CardDescription
                className={cn({
                  "md:tw-text-base": first,
                })}
              >
                <span className="tw-font-black ">
                  {`${millify(uses) ?? 0} uses · ${millify(likes ?? 0) ?? 0} ${likes === 1 ? "like" : "likes"}`}
                </span>
              </CardDescription>
            </div>
          )}
          <CardTitle
            className={cn({ "sm:tw-text-2xl md:tw-text-4xl": isTopTrending })}
          >
            {artistname}
          </CardTitle>

          <CardAuthor
            className={cn("tw-hidden", {
              "tw-text-md tw-mt-1 tw-font-black sm:tw-block": isTopTrending,
            })}
          >
            By {user?.username}
          </CardAuthor>
          <CardDescription
            className={cn("tw-truncate", {
              "sm:tw-hidden md:tw-text-base": isTopTrending,
            })}
          >
            <span>
              {`${millify(uses) ?? 0} uses · ${millify(likes ?? 0) ?? 0} ${likes === 1 ? "like" : "likes"} · ${
                user?.username
              }`}
            </span>
          </CardDescription>
          {topPicks && first && (
            <Button
              data-testid="btn-voicify-trending"
              className={cn(
                "tw-bottom-5 tw-mt-2 tw-hidden tw-w-auto tw-cursor-pointer sm:tw-absolute tw-rounded-full md:tw-px-4",
                {
                  "sm:tw-flex": first,
                },
              )}
            >
              <Icon width={"25px"} height={"25px"} icon="voiceWhite" />
              <span className="">Jam with {artistname}</span>
            </Button>
          )}
        </CardContent>
      </Card>
    </Link>
  );
}
