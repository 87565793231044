import { getAuthBearerHeaders } from "../shared";

export interface AudioEffect {
  type:
    | "HighpassFilter"
    | "LowpassFilter"
    | "NoiseGate"
    | "Compressor"
    | "Gain"
    | "Reverb";
  params: { [key: string]: number | string | boolean };
}

export interface AudioModifierConfig {
  effects: AudioEffect[];
  timeStretch: { [key: string]: number | string | boolean };
}

export interface GenerateFileAudioJobResponse {
  url: string;
  id: string;
}

export async function mutationGenerateFileAudioJob(
  extension: "mp3" | "wav",
  modifiers: AudioModifierConfig,
): Promise<GenerateFileAudioJobResponse> {
  const response = await fetch(`/api/audio-tool/get-signed-url`, {
    method: "POST",
    headers: {
      ...(await getAuthBearerHeaders()),
    },
    body: JSON.stringify({
      extension,
      effects: modifiers.effects,
      time_stretch: modifiers.timeStretch,
    }),
  });

  if (response.status != 200) {
    throw new Error(`${response?.statusText}`);
  }

  const data = await response.json();
  return {
    url: data.url,
    id: data.id,
  };
}

export async function mutationGenerateYoutubeAudioJob(
  youtubeUrl: string,
  modifiers: AudioModifierConfig,
): Promise<string> {
  const response = await fetch(`/api/audio-tool/youtube-upload`, {
    headers: {
      ...(await getAuthBearerHeaders()),
    },
    method: "POST",
    body: JSON.stringify({
      youtubeUrl,
      ...{
        effects: modifiers.effects,
        time_stretch: modifiers.timeStretch,
      },
    }),
  });

  if (response.status != 200) {
    throw new Error(`${response?.statusText}`);
  }

  const data = await response.json();
  return data.id;
}
