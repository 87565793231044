import { cn } from "@jammable/ui-core";
import { ModelForGrid, ModelGridItem } from "./ModelGridItem";

export interface ModelGridProps<M> {
  items: M[];
  topPicks?: boolean;
  idx?: number;
}

export function ModelGrid<M extends ModelForGrid>({
  items,
  topPicks,
  idx,
}: ModelGridProps<M>) {
  return (
    <div className="tw-overflow-x-auto tw-overflow-y-hidden sm:tw-overflow-x-hidden">
      <ul
        className={cn(
          "tw-m-0 tw-list-none tw-p-0",
          {
            "tw-flex tw-flew-nowrap sm:tw-flex-none sm:tw-grid sm:tw-grid-cols-2":
              topPicks,
          },
          { "tw-grid tw-grid-cols-2": !topPicks },
          "tw-gap-3 md:tw-grid-cols-3 md:tw-gap-5 lg:tw-grid-cols-4",
        )}
      >
        {items.map((model, i) => {
          const isFirstItemHighlight = topPicks && i === 0;
          return (
            <div
              key={model.artistname}
              className={cn("tw-m-0 tw-shrink-0 tw-basis-72 tw-p-0", {
                "tw-col-span-1 sm:tw-col-span-2 md:tw-col-span-3":
                  isFirstItemHighlight,
              })}
            >
              <ModelGridItem
                topPicks={topPicks}
                first={isFirstItemHighlight}
                model={model}
                idx={idx ? idx + i : undefined}
              />
            </div>
          );
        })}
      </ul>
    </div>
  );
}
