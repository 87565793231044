import {
  fetchTrendingModels,
  fetchCategoriesAndTopModels,
} from "@jammable/clientapi";

export async function getHomepageModels() {
  // fetching all desired models in parallel
  // will result in a faster page load and allows
  // caching full page easily
  const [trending, categoryTopModels] = await Promise.all([
    fetchTrendingModels(),
    fetchCategoriesAndTopModels(),
  ]);

  return {
    trending,
    categoryTopModels,
  };
}
