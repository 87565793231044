import { cn } from "@jammable/ui-core";
import { Icon } from "../Media";

export function SocialBar({ className }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "tw-mb-5 tw-w-64 tw-rounded-full tw-bg-white tw-pb-2 tw-pt-3 tw-shadow-lg",
        className,
      )}
    >
      <p className="tw-my-1 tw-text-center tw-font-bold tw-leading-tight tw-text-black tw-opacity-50">
        As seen on...
      </p>
      <div className="tw-mb-2 tw-flex tw-h-12 tw-justify-center tw-gap-3">
        <a
          target="_blank"
          href="https://www.youtube.com/@jammable"
          rel="noreferrer"
        >
          <Icon icon={"youtube"} alt="Youtube" />
        </a>
        <a
          target="_blank"
          href="https://www.tiktok.com/@getjammable"
          rel="noreferrer"
        >
          <Icon icon={"tiktok"} alt="Tiktok" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/getjammable/"
          rel="noreferrer"
        >
          <Icon icon={"instagram"} alt="Instagram" />
        </a>
      </div>
      {/* TODO i18n */}
    </div>
  );
}
