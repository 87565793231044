import { getAuth } from "firebase/auth";
import { ModelInfo } from "../conversions";
import { BASE_URL, getAuthHeaders } from "../shared";
import axios from "axios";

type FetcherProps = { limit?: number };

export async function fetchTrendingModels({
  limit = 6,
}: FetcherProps = {}): Promise<ModelInfo[]> {
  const response = await fetch(
    `${BASE_URL}/api/model/getTrendingModels?limit=${limit}`,
  );
  const data: { models: ModelInfo[] } = await response.json();
  return data.models;
}

export async function fetchTrendingCommunityModels({
  limit = 4,
}: FetcherProps = {}): Promise<ModelInfo[]> {
  const response = await fetch(
    `${BASE_URL}/api/model/getTrendingCommunityModels?limit=${limit}`,
  );
  const data: {
    models: {
      _id: string;
      model: Omit<ModelInfo, "_id">;
    }[];
  } = await response.json();
  // this endpoint nests the models attributes inside a model attribute
  // to make them standard we map the response
  return data.models.map<ModelInfo>(({ _id, model }) => ({ _id, ...model }));
}

export async function fetchCategoriesAndTopModels({
  limit = 8,
}: FetcherProps = {}): Promise<ModelInfo[]> {
  const response = await fetch(
    `${BASE_URL}/api/model/getModelsByCategory?limit=${limit}`,
  );
  const data: { models: ModelInfo[] } = await response.json();
  return data.models;
}

export async function searchModels(
  term: string,
  mode: string,
): Promise<ModelInfo[]> {
  const payload = {
    params: {
      search: term,
      limit: 10,
      sortBy: "usesLast24Hours",
      sortOrder: "desc",
      page: 1,
      mode,
    },
    headers: {},
  };
  if (mode === "private" || mode === "saved") {
    const headers = await getAuthHeaders();
    payload.headers = headers;
  }
  const response = await axios.get(`${BASE_URL}/api/model`, payload);
  console.log(response.data);
  return response.data.data;
}

export async function fetchDuetMatchingModels({
  modelIds,
}: {
  modelIds: string[];
}): Promise<ModelInfo[]> {
  const response = await fetch(
    `${BASE_URL}/api/model/getDuetMatches?modelIds=${modelIds.join(",")}`,
  );
  const data: { models: ModelInfo[] } = await response.json();
  return data.models;
}
export async function fetchRecentCombos({
  setIsRecentCombosLoading,
}: {
  setIsRecentCombosLoading: React.Dispatch<React.SetStateAction<boolean>>;
}): Promise<{ models: ModelInfo[]; createdAt: string }[]> {
  const headers = await getAuthHeaders();
  if (Object.keys(headers).length === 0) {
    // No auth headers present, return an empty array
    return [];
  }

  setIsRecentCombosLoading(true);

  try {
    const response = await fetch(`${BASE_URL}/api/duets/getRecentCombos`, {
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: { models: ModelInfo[]; createdAt: string }[] = await response
      .json()
      .then((d) => d.models);
    return data;
  } catch (error) {
    console.error("Error fetching recent combos:", error);
    return [];
  } finally {
    setIsRecentCombosLoading(false);
  }
}
