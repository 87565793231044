import { BASE_URL, getAuthBearerHeaders } from "../shared";

export interface ModelInfo {
  artistname: string;
  urlParam: string;
  image: { url: string; blurhash: string };
  _id: string;
  likes: number;
  uses: number;
  private: boolean;
  trending: {
    isTrending: boolean;
    position: number;
  };
  user: {
    username: string;
  };
  info: {
    uploadedBy: string;
  };
  pitchScaling?: number;
}

export interface Conversion {
  _id: string;
  conversionStatus: string;
  isFree: boolean;
  shortid: string;
  name?: string;
  userid: string;
  downloads: number;
  status: "waiting" | "pending" | "success" | "failed";
  info: {
    acapella: "extracting" | "extracted";
    model: "waiting" | "applying" | "applied";
  };
  instrumentalUrl?: string;
  combinedUrl?: string;
  convertedUrl?: string;
  model: {
    artistname: string;
    urlParam: string;
    image: { url: string };
  };
  user: {
    username: string;
    avatar: string;
    firebaseUid: string;
    credits: number;
    customModels: number;
    subscription: "free" | "basic" | "creator" | "power";
  };
  models?: ModelInfo[];
  duration?: number;
  clip?: {
    inTime?: number;
    outTime?: number;
    duration?: number;
  };
}

export async function fetchConversion(id: string): Promise<Conversion> {
  console.log(BASE_URL);
  const response = await fetch(
    `${BASE_URL}/api/model/getConversion?conversionId=${id}`,
    {
      headers: {
        ...(await getAuthBearerHeaders()),
      },
    },
  );
  const data = await response.json();
  return data.conversion;
}

export async function downloadConversion(
  id: string,
  type: "instrumental" | "combined" | "acapella",
): Promise<string> {
  const response = await fetch(
    `${BASE_URL}/api/s3/downloadFileUrl?conversionId=${id}&type=${type}`,
    {
      headers: {
        ...(await getAuthBearerHeaders()),
      },
    },
  );
  const data = await response.json();
  return data.url;
}
