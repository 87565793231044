import React from "react";
import { cn } from "@jammable/ui-core";

export const primaryBadgeStyles = cn(
  "tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-3xl tw-bg-violet-50 tw-px-3 tw-py-2 tw-align-middle tw-text-sm tw-font-bold tw-leading-normal tw-text-purple-700 hover:tw-bg-violet-100 md:tw-text-base",
);

export const Badge = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(primaryBadgeStyles, className)} {...props} />
));

Badge.displayName = "Badge";

export const secondaryBadgeStyles = cn(
  primaryBadgeStyles,
  "tw-rounded-4xl tw-bg-neutral-100 tw-px-4 tw-py-1 tw-text-black",
);

export const SecondaryBadge = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(secondaryBadgeStyles, className)} {...props} />
));

SecondaryBadge.displayName = "SecondaryBadge";
