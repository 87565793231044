import { cn } from "@jammable/ui-core";
import {
  BackgroundImage,
  BackgroundImageMain,
  getUrlForSrc,
} from "@jammable/ui-components/Media";
import bannerImg from "@jammable/ui-components/Media/backgrounds/banner.png";

export function Banner() {
  return (
    <>
      <div className="md:tw-hidden">
        <h1
          style={{
            backgroundImage: `url(${getUrlForSrc(bannerImg)})`,
            backgroundColor: "black", // just as fallback while image loads
          }}
          className={cn(
            `tw-m-0 tw-stroke-slate-50 tw-pt-0 tw-pb-3 tw-text-5xl tw-font-black tw-tracking-tighter sm:tw-text-6xl`,
            `tw-bg-right-bottom [background-clip:text] [color:transparent]`,
          )}
        >
          Create AI Covers with your Favorite Voices
        </h1>
      </div>
      <div className={cn("tw-hidden md:tw-block")}>
        <BackgroundImageMain
          priority
          width={1400}
          height={430}
          src={bannerImg}
          className="tw-overflow-hidden tw-rounded-3xl tw-text-center tw-text-white"
        >
          <div className="tw-py-20 [filter:drop-shadow(0_0_2.5rem_black)]">
            {/* TODO i18n this, new package for this soon */}
            <h1 className="tw-m-auto tw-mb-2 tw-text-5xl tw-font-black tw-tracking-tighter [filter:drop-shadow(0_0_1rem_black)] md:tw-w-[750px] md:tw-text-7xl">
              Create AI Covers with your Favorite Voices
            </h1>
            <p className="tw-px-2 tw-text-sm tw-font-black tw-tracking-widest tw-uppercase tw-leading-loose [filter:drop-shadow(0_0_1rem_black)]">
              The #1 platform for making high quality AI covers in seconds!
            </p>
          </div>
        </BackgroundImageMain>
      </div>
    </>
  );
}
